
import { defineComponent } from 'vue'

import LinkIcon from '@/assets/svg/link-icon.svg?inline'

import Popup from '@/components/molecules/PopupRef.vue'

export default defineComponent({
  name: 'RefLink',
  data () {
    return {
      isModalOpen: false,
      translateData: {},
      hasPayment: {},
      hasPaymentNew: ''
    }
  },
  components: {
    Popup,
    LinkIcon
  },
  mounted () {
    this.hasPayment = JSON.parse(localStorage.getItem('userData'))
    this.hasPaymentNew = JSON.parse(localStorage.getItem('has_payments'))
    this.translate()
  },
  watch: {
    hasPayment () {
      if (this.hasPayment.has_payments) {
        this.hasPaymentNew = true
      }
    }
  },
  methods: {
    translate (data) {
      data = []
      data.push('referral_link')
      this.$store.dispatch('getPayments/translate', data).then(
        (response) => {
          this.translateData = response
        },
        (error) => {
          console.log(error)
        }
      )
    },
    handleCloseModal () {
      this.isModalOpen = false
    },
    modalOpen () {
      this.isModalOpen = true
    }
  }
})
