
import { defineComponent } from 'vue'

import CloseIcon from '@/assets/svg/close-icon.svg?inline'
import LinkIcon from '@/assets/svg/link-icon.svg?inline'

import VueQrcode from '@chenfengyuan/vue-qrcode'

export default defineComponent({
  name: 'Popup',
  data () {
    return {
      translateData: {},
      refCode: {}
    }
  },
  props: {
    onClose: {
      type: Function,
      default () {
        return {}
      }
    }
  },
  mounted () {
    this.translate()
    this.getRef()
    this.hasPaymentNew = JSON.parse(localStorage.getItem('has_payments'))
  },
  components: {
    VueQrcode,
    LinkIcon,
    CloseIcon
  },
  methods: {
    translate (data) {
      data = []
      data.push('referral_link', 'copy')
      this.$store.dispatch('getPayments/translate', data).then(
        (response) => {
          this.translateData = response
        },
        (error) => {
          console.log(error)
        }
      )
    },
    getRef () {
      this.$store.dispatch('getPayments/getProfile').then(
        (response) => {
          this.refCode = response.data
        },
        (error) => {
          console.log(error)
        }
      )
    },
    handleCloseModal () {
      this.onClose()
    },
    copyLink () {
      var copyLink = document.getElementById('ref') as HTMLInputElement
      copyLink.select()
      document.execCommand('copy')
    }
  }
})
